@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.header {
    width: 100%;
    padding: 20px 0;
}
.header_wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.header_logo_link {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    width: 200px;
    height: 50px;
}
.header_logo_link img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.headers_navigation_links_mail_address_info_wrapper {
    width: 100%;
    max-width: 700px;
}
.headers_navigation_links_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.headers_navigation_link {
    width: 24.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: #d9d9da;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
}


.headers_mail_address_info_item_wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.headers_mail_address_info_item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 32%;
}

.header_mail_links_wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

}

.headers_mail_address_info_item_icon {
    margin-right: 10px;
}

.header_mail_link {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #333333;
    font-weight: 400;
    margin-bottom: 2px;
    text-decoration: none;
    outline: none;
}

.headers_mail_address_info_item_text {
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    color: #333333;
    font-weight: 400;
}




.footer_item2 {
    width: 48%;
}


@media (max-width: 1460px) {
    .header_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}

@media (min-width: 851px) and (max-width: 1070px) {
    .headers_navigation_links_mail_address_info_wrapper {
        max-width: 540px!important;
    }
    .headers_navigation_link {
        font-size: 14px!important;
        padding: 6px!important;
    }
    .headers_mail_address_info_item {
        width: 50% !important;
    }
}

@media (max-width: 851px) {
    .header_hamburger_icon {
        display: flex!important;
    }
    .headers_navigation_links_mail_address_info_wrapper {
        display: none!important;
    }
    .mobile_headers_navigation_links_mail_address_info_wrapper {
        width: 100%;
    }

    .headers_navigation_links_wrapper {
        flex-direction: column;
    }
    .headers_navigation_link {
        width: 100% !important;
        margin-bottom: 6px!important;
    }

    .headers_mail_address_info_item_wrapper {
        flex-direction: column;
    }

    .headers_mail_address_info_item {
        width: 100%;
        margin-bottom: 10px;
    }
}


.mobile_menu {
    /*display: none;*/
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background:  rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
}
.mobile_menu_wrapper {
    height: 100%;
    width: 320px;
    position: absolute;
    z-index: 99;
    right: 0;
    top: 0;
    padding-top: 80px;
    padding-left: 15px;
    overflow: scroll;
    padding-right: 15px;
    padding-bottom: 20px;
    background: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

}
.mobile_menu_close_btn {
    cursor: pointer;
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
}


.header_hamburger_icon {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    width: 50px;
    height: 50px;
    display: none;
}

.header_hamburger_icon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (max-width: 425px) {
    .header_logo_link img {
         width: 150px;
    }
}
