@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/*top*/

.top {
    width: 100%;
    margin-bottom: 30px;
}
.top_wrapper {
    width: 100%;
}

.top_img {
    width: 100%;
}

.top_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/*company_history*/

.company_history {
    width: 100%;
    margin-bottom: 60px;
    position: relative;
}
.company_history_wrapper {
    /*max-width: 1440px;*/
    width: 100%;
    margin: 0 auto;
}
.company_history_title {
    color: #dd2b1c;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.company_history_items_wrapper {
    width: 100%;
}

.company_history_info_item {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
}
.company_history_info_item_text {
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
}

.company_history_img_item {
    width: 100%;
}
.company_history_img_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
/*cars_models*/
.cars_models {
    width: 100%;
    margin-bottom: 60px;
}
.cars_models_wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
}
.cars_models_title {
    color: #dd2b1c;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 50px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.cars_models_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

}
.cars_models_item {
    width: 32%;
    border: 1px solid #ababab;
    height: 472px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
}

.cars_models_item_img {
    width: 100%;
    /* height: 100%; */
    height: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cars_models_item_img .cars_models_item_img_child {
    width: 100%;
    height: 80%;
    object-fit: cover;
    position: absolute;
}

.cars_models_item:first-child .cars_models_item_img {
    background-image: url("../images/car_model_back_img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cars_models_item:first-child  .cars_models_item_img_child {
    left: 0px;
    bottom: -29px;
}
.cars_models_item:nth-child(2) .cars_models_item_img_child {
    left: -85px;
    bottom: 6px;
    height: 57% !important;
}
.cars_models_item:nth-child(3) .cars_models_item_img_child {
    left: -79px;
    bottom: -30px;
}

.cars_models_item:nth-child(2) .cars_models_item_img {
    background-image: url("../images/car_model_back_img.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cars_models_item:nth-child(3) .cars_models_item_img {
    background-image: url("../images/car_model_back_img2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.cars_models_item_info_box {
    width: 100%;
    padding: 10px 20px;
    margin-bottom: 30px;
}

.cars_models_item_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
    width: 100%;
    border-bottom: 2px solid #000000;
    padding-bottom: 10px;
}

.cars_models_item_info {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
    height: 44px;
}
.more_details_button {
    background: #d9dbdd;
    width: 130px;
    padding: 5px;
    cursor: pointer;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    bottom: 20px;
    border: none;
}

.more_details_button img {
    width: 20px;
    height: 20px;
}

.more_details_button_text {
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    margin-right: 5px;
}
/*car_models_details*/
.car_models_details {
    width: 100%;
    /*margin-bottom: 60px;*/
}
.car_models_details_wrapper {
    width: 100%;
}
.car_models_details_img {
    width: 100%;
    border-bottom: 10px solid #dd2b1c;
    height: 694px;
}
.car_models_details_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


/*company_news*/

.company_news {
    width: 100%;
    margin-bottom: 60px;
}
.company_news_wrapper {
    max-width: 1440px;
    margin: 0 auto;
    width: 100%;
}
.company_news_title {
    color: #dd2b1c;
    font-weight: 600;
    font-size: 28px;
    margin-bottom: 50px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
}

.company_news_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
    flex-wrap: wrap;
}

.company_news_item {
    width: 32%;
    background: #ececec;
    border: 1px solid #dd2b1c;
    padding: 15px 15px 30px 15px;
    border-radius: 20px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;

}
.company_news_item_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.company_news_item_img {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;
    height: 280px;
}
.company_news_item_date_info {
    margin-bottom: 15px;
    color: #333333;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 15px;
}

.company_news_item_title {
    margin-bottom: 10px;
    color: #333333;
    font-weight: 600;
    font-size: 18px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 15px;
}
.company_news_item_info {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 15px;
    max-height: 80px;
    overflow: hidden;
}
.company_news_item_info2 {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    padding-left: 15px;
    /*max-height: 80px;*/
    /*overflow: hidden;*/
}

.company_news_item_more_button {
    background: #ffffff;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    margin: 0 auto;
    padding: 6px 5px;
    cursor: pointer;
}

.company_news_item_more_button_text {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-right: 3px;
}
.company_news_item_more_button img {
    width: 12px;
    height: 12px;
}

.company_news_item_img_info_box {
    width: 100%;
}

.load_more_news_button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background: none;
    cursor: pointer;
    outline: none;
    border: none;
}

.load_more_news_button_text {
    color: #dd2b1c;
    font-weight: 500;
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    margin-right: 10px;
}

/*how_to_become_dealer*/
.how_to_become_dealer {
    width: 100%;
    margin-bottom: 60px;
}
.how_to_become_dealer_wrapper {
    width: 100%;
}
.how_to_become_dealer_img {
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    height: 600px;
}
.how_to_become_dealer_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.how_to_become_dealer_img-title {
    position: absolute;
    top: 20%;
    left: 65%;
    transform: translate(-50%, 93%);
    z-index: 999;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    color: #cd4e40;
    font-size: 42px;
    /*right: -17%;*/
    width: 100%;
    max-width: 700px;
}

.how_to_become_dealer_info_wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;

}

.how_to_become_dealer_title {
    color: #000000;
    font-weight: 700;
    font-size: 25px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 20px;
}

.how_to_become_dealer_info {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 70px;
}

.how_to_become_dealer_items_wrapper {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.how_to_become_dealer_item {
    width: 32%;
    background: #f8f8fc;
    padding: 20px 10px;
    display: flex;
    /*justify-content: center;*/
    align-items: center;
    flex-direction: column;
    height: 275px;

}

.how_to_become_dealer_item_icon {
    margin-bottom: 20px;
}
.how_to_become_dealer_item_title {
    color: #000000;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 10px;
    text-align: center;
}
.how_to_become_dealer_item_info {
    color: #333333;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
}

/*partners*/
.partners {
    width: 100%;
    background-image: url("../images/auto.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 1300px;
}
.partners_wrapper {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding-top: 180px;
}
.partners_title {
    color: #ffffff;
    font-weight: 700;
    font-size: 45px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-bottom: 150px;
}

.partners_list_items_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.partners_list_item {
    color: #ffffff;
    font-weight: 600;
    font-size: 28px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    /*margin: 0 auto;*/
    margin-bottom: 45px;
    width: 24%;
}

/*top_slider*/

.top_slider {
    width: 100%;
    margin-bottom: 60px;
}
.top_slider_wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.top_slider_box {
    width: 60%;
}
.top_slider_item_wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

/*.top_slider_img_item  {*/
/*    width: 60%;*/
/*    height: 750px;*/

/*}*/
.top_slider_img_parent {
    width: 100%;
    height: 772px;
}

.top_slider_img_parent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.top_slider_info_item {
    width: 40%;
    padding: 0 6px;
    height: 750px;
    position: relative;
}

.top_slider_info_item_child {
    width: 100%;
    display: flex;
    justify-content: space-between;
    /*align-items: center;*/
    border-bottom: 1px solid #333333;
    /*padding: 10px;*/
}

.top_slider_info_item_child_title {
    color: #333333;
    font-weight: 500;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
}
.top_slider_info_item_child_detail {
    color: #333333;
    font-weight: 500;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    text-align: end;
    /*flex: 1;*/
}


.top_slider_info_item_line {
    top: -24px;
    width: 2px;
    height: 100%;
    position: absolute;
    background: #333333;
    left: 52%;
}

.prev_next_btns_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 999;
    bottom: 50%;
}

.prev {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 25px;
}
.next {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 25px;
}

.top_slider_info_item_child_title_parent {
    width: 60%;
    padding: 10px;
    border-right: 1px solid #333333;
}
.top_slider_info_item_child_detail_parent {
    width: 40%;
    padding: 10px;
}


/*popup*/

.show_mpv_popup {
    /*display: none;*/
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background:  rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
    padding-top: 50px;
}

.show_mpv_popup_wrapper {
     width: 100%;
    max-width: 800px;
    margin: 0 auto;
     background: #ffffff;
     border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 60px 30px 50px 30px;
    margin: 0 auto;
    position: relative;
}

.show_mpv_popup_img img {
    width: 100%;
    height: 100%;
}


.show_mpv_popup_img {
    width: 100%;
    /*height: 400px;*/
    margin: 0 auto;
    border-bottom: 10px solid #dd2b1c;
}

.show_mpv_popup_close_btn {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    position: absolute;
    right: 20px;
    top: 20px;


}

@media (max-width: 1460px) {
    .company_history_info_item {
        max-width: unset!important;
        width: 95% !important;
    }
    .cars_models_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .car_models_details_img {
        height: unset!important;
    }
    .company_news_wrapper {
        width: 95% !important;
        max-width: unset!important;
    }
    .how_to_become_dealer_info_wrapper {
        width: 95% !important;
        max-width: unset!important;
    }
}


@media (min-width: 1143px) and (max-width: 1460px) {
    .cars_models_item:nth-child(2) .cars_models_item_img_child {
        height: 44% !important;
    }
    .cars_models_item .cars_models_item_img_child {
        height: 60% !important;
    }

}
@media (min-width: 851px) and  (max-width: 1143px) {
    .cars_models_item:nth-child(2) .cars_models_item_img_child {
        height: 35% !important;
    }
    .cars_models_item .cars_models_item_img_child {
        height: 50% !important;
    }

}

@media (min-width: 551px) and (max-width: 851px) {
    .cars_models_items_wrapper {
        flex-wrap: wrap;
    }
    .cars_models_item {
        width: 100%!important;
        margin-bottom: 25px;
        height: 620px!important;
    }
    .cars_models_item:nth-child(2) .cars_models_item_img_child {
        height: 50%!important;
    }
    .cars_models_item .cars_models_item_img_child {
        height: 70% !important;
    }

}
@media (min-width: 391px) and  (max-width: 551px) {
    .cars_models_items_wrapper {
        flex-wrap: wrap;
    }
    .cars_models_item {
        width: 100%!important;
        margin-bottom: 25px;
        height: 520px!important;
    }
    .cars_models_item:nth-child(2) .cars_models_item_img_child {
        height: 40%!important;
    }
    .cars_models_item .cars_models_item_img_child {
        height: 60% !important;
    }

}
@media (max-width: 391px) {
    .cars_models_items_wrapper {
        flex-wrap: wrap;
    }
    .cars_models_item {
        width: 100%!important;
        margin-bottom: 25px;
        height: 520px!important;
    }
    .cars_models_item:nth-child(2) .cars_models_item_img_child {
        height: 35%!important;
    }
    .cars_models_item .cars_models_item_img_child {
        height: 50% !important;
    }
    .cars_models_item:nth-child(3) .cars_models_item_img_child {
        left: -34px!important;
    }
    .cars_models_item:nth-child(2) .cars_models_item_img_child {
        left: -45px!important;
    }

    }

@media (min-width: 501px) and (max-width: 768px) {
    .show_mpv_popup_img {
        height: 250px!important;
    }
}
@media  (max-width: 501px) {
    .show_mpv_popup_img {
        height: unset!important;
    }
}


@media (min-width: 495px) and (max-width: 1251px) {
    .top_slider_wrapper {
        flex-direction: column;

    }
    .top_slider_box {
        width: 100% !important;
        height: 747px;
    }
    .top_slider_img_parent {
        height: 747px!important;
    }
    .top_slider_info_item {
        width: 100% !important;
        height: unset!important;
    }
}
@media  (min-width: 375px) and (max-width: 495px) {
    .top_slider_wrapper {
        flex-direction: column;

    }
    .top_slider_box {
        width: 100% !important;
        height: 290px!important;
    }
    .top_slider_info_item {
        width: 100% !important;
        height: unset!important;
    }
    .top_slider_img_parent {
        height: 290px!important;
    }
    .top_slider_info_item_child_title {
        font-size: 14px!important;
    }
    .top_slider_info_item_child_detail {
        font-size: 12px!important;
    }
}
@media   (max-width: 375px) {
    .top_slider_wrapper {
        flex-direction: column;

    }
    .top_slider_box {
        width: 100% !important;
        height: 270px!important;
    }
    .top_slider_info_item {
        width: 100% !important;
        height: unset!important;
    }
    .top_slider_img_parent {
        height: 270px!important;
    }
    .top_slider_info_item_child_title {
        font-size: 12px!important;
    }
    .top_slider_info_item_child_detail {
        font-size: 10px!important;
    }
}


@media (min-width: 769px) and (max-width: 1070px) {
    .company_news_item_img {
        height: 200px!important;
    }
    .company_news_item {
        min-height: 500px!important;
    }
}

@media (min-width: 426px) and (max-width: 769px) {
    .company_news_items_wrapper {
        flex-wrap: wrap;
    }
    .company_news_item_img {
        height: 280px!important;
    }
    .company_news_item {
        min-height: 600px!important;
        width: 100% !important;
        margin-bottom: 20px;
    }
}
@media (max-width: 426px) {
    .how_to_become_dealer_info {
        font-size: 14px!important;
    }
    .how_to_become_dealer_title {
        font-size: 23px!important;
    }
    .company_news_items_wrapper {
        flex-wrap: wrap;
    }
    .company_news_item_img {
        height: 200px!important;
    }
    .company_news_item {
        min-height: 500px!important;
        width: 100% !important;
        margin-bottom: 20px;
    }
}


@media (min-width: 426px) and (max-width: 600px) {
    .how_to_become_dealer_img {
        height: 500px!important;
    }
}
@media (min-width: 376px) and (max-width: 426px) {
    .how_to_become_dealer_img {
        height: 400px!important;
    }
    .how_to_become_dealer_img-title {
        font-size: 32px!important;
    }
}
@media  (max-width: 376px) {
    .how_to_become_dealer_img {
        height: 300px!important;
    }
    .how_to_become_dealer_img-title {
        font-size: 25px!important;
    }
}


@media (min-width: 769px) and (max-width: 900px) {
    .how_to_become_dealer_item_info {
        font-size: 12px!important;
    }
}

@media (max-width: 769px) {
    .how_to_become_dealer_items_wrapper {
        flex-wrap: wrap;
    }

    .how_to_become_dealer_item {
        width: 100% !important;
        margin-bottom: 20px;
    }
}

@media (min-width: 585px) and (max-width: 768px) {
    .partners_wrapper {
        padding-top: 150px!important;
    }
    .partners_title {
        font-size: 40px!important;
    }
    .partners_list_item {
        font-size: 26px!important;
        width: 32% !important;
    }
    .partners {
        height: 1000px!important;
    }
}
@media  (min-width: 475px) and (max-width: 585px) {
    .partners_wrapper {
        padding-top: 120px!important;
    }
    .partners_title {
        font-size: 30px!important;
    }
    .partners_list_item {
        font-size: 23px!important;
        width: 32% !important;
    }
    .partners {
        height: 1000px!important;
    }
}
@media (min-width: 426px) and  (max-width: 475px) {
    .partners_wrapper {
        padding-top: 100px!important;
    }
    .partners_title {
        font-size: 27px!important;
    }
    .partners_list_item {
        font-size: 22px!important;
        width: 48% !important;
    }
    .partners {
        height: 900px!important;
    }
}
@media  (min-width: 355px) and (max-width: 426px) {

    .partners_wrapper {
        padding-top: 80px!important;
    }
    .partners_title {
        font-size: 24px!important;
        margin-bottom: 100px!important;
    }
    .partners_list_item {
        font-size: 20px!important;
        width: 48% !important;
    }
    .partners {
        height: 700px!important;
    }
}
@media   (max-width: 355px) {
    .partners_wrapper {
        padding-top: 60px!important;
    }
    .partners_title {
        font-size: 20px!important;
        margin-bottom: 100px!important;
    }
    .partners_list_item {
        font-size: 18px!important;
        width: 48% !important;
    }
    .partners {
        height: 600px!important;
    }
}

.show_news_single_page_popup {
    /*display: none;*/
    position: fixed; /* Stay in place */
    z-index: 9999; /* Sit on top */
    left: 0;
    /*right: -1000px;*/
    /*transition: 0.5s;*/
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background:  rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(20px);
    padding-top: 50px;
}

.show_news_single_page_popup_wrapper {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px 30px 50px 30px;
    position: relative;
}

.news_slider_img_parent2 {
    width: 100%;
    height: 280px;
}
.news_slider_img_parent2 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


@media (max-width: 600px) {
    .show_news_single_page_popup_wrapper {
        max-width: unset!important;
        width: 95%!important;
    }
}

@media (min-width: 508px) and (max-width: 1003px) {
    .how_to_become_dealer_img-title {
        left: 50% !important;
        transform: translate(-50%, 60%) !important;
        max-width: 500px!important;
    }
}
@media (min-width: 398px) and  (max-width: 508px) {
    .how_to_become_dealer_img-title {
        left: 50% !important;
        transform: translate(-50%, 60%) !important;
        max-width: 400px!important;
    }
}
@media (max-width: 398px) {
    .how_to_become_dealer_img-title {
        left: 50% !important;
        transform: translate(-50%, 60%) !important;
        max-width: 300px!important;
    }
}


@media (max-width: 820px) {
    .show_mpv_popup_wrapper {
        max-width: unset!important;
        width: 90% !important;
    }
}

.download_btn {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000;
    margin-top: 50px;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    padding: 10px;
}


.download_btn img {
    margin-left: 7px;
}

@media (max-width: 425px) {
    .news_slider_img_parent2 {
        height: unset!important;
    }
}
