@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.footer {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
}
.footer_wrapper {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.footer_logo_link {
    display: flex;
    align-items: center;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    width: 200px;
    height: 50px;
    margin-bottom: 30px;
}
.footer_logo_link img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.footer_navigation_links_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.footer_navigation_link {
    width: 24.5%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    outline: none;
    background: #d9d9da;
    padding: 10px;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #333333;
    font-weight: 500;
}


.footer_item1 {
    width: 48%;
}


.checkbox_label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;
    /*width: 30px;*/
    /*height: 30px;*/
    flex: 1;
}

/* Hide the browser's default checkbox */
.checkbox_label input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border: 1px solid #333333;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_label input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_label .checkmark:after {
    content: '';
    background-image: url("../svg/news_check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 9%;
    transform: translate(-50%, -7%);
    background-color: #dd2b1c;
    border-radius: 3px;
}

.checkbox_label input:checked ~ .checkmark {
    border: 1px solid transparent!important;

}

.checkbox_label2 input:checked ~ .checkmark2 {
    border: 1px solid transparent!important;

}
.checkbox_label_info {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    flex: 1;
}
/**/


.checkbox_label2 {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin-bottom: 15px;
    /*width: 30px;*/
    /*height: 30px;*/
    flex: 1;
}

/* Hide the browser's default checkbox */
.checkbox_label2 input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark2 {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 3px;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    border: 1px solid #333333;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark2:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_label2 input:checked ~ .checkmark2:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_label2 .checkmark2:after {
    content: '';
    background-image: url("../svg/news_check_icon.svg");
    background-repeat: no-repeat;
    background-position: center;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 9%;
    transform: translate(-50%, -7%);
    background-color: #dd2b1c;
    border-radius: 3px;
    border: 1px solid transparent!important;
}

.checkbox_label_info {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #333333;
    font-weight: 400;
    flex: 1;
}

.customer_support_service_info_phone_wrapper {
    width: 100%;
    margin-bottom: 25px;
}

.customer_support_service_info_phone {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.customer_support_service_info_phone_number {
    color: #dd2b1c;
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 600;
    display: flex;
    cursor: pointer;
    outline: none;
}

.social_links_title_wrapper {
    width: 100%;
    margin-bottom: 25px;
}


.social_links_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.social_links_wrapper {
    width: 150px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.social_link {
    display: flex;
    cursor: pointer;
    outline: none;
    text-decoration: none;
}


.footer_news_form {
    background: #f6f7f8;
    padding: 20px;
}
.footer_news_form_title {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    color: #333333;
    font-weight: 500;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.footer_news_form_input_wrapper {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #000000;
    height: 40px;
    overflow: hidden;
}

.footer_news_form_input_field {
    width: 100%;
    cursor: pointer;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #516bb1;
    font-weight: 500;
    height: 40px;
    border: none;
    padding: 0 15px;

}
.footer_news_form_input_field::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #516bb1;
    font-weight: 500;

}

.footer_news_form_input_button_wrapper {
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #000000;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 40px;
    overflow: hidden;
}
.subscribe_to_news_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
    border: none;
    width: 50%;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    background: #1e2c39;
    height: 40px;

}



.footer_news_form_input_field2 {
    width: 50%;
    cursor: pointer;
    outline: none;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    color: #516bb1;
    font-weight: 500;
    height: 40px;
    border: none;
    padding: 0 15px;

}
.footer_news_form_input_field2::placeholder {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: #516bb1;
    font-weight: 500;
}


@media (max-width: 1460px) {
    .footer_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}

@media (max-width: 1460px) {
    .footer_navigation_links_wrapper {
        flex-wrap: wrap;
    }
    .footer_navigation_link {
        width: 49.5% !important;
        margin-bottom: 5px;
    }
}


@media (max-width: 1070px) {
    .subscribe_to_news_btn {
        font-size: 14px!important;
    }
}


@media (max-width: 900px) {
    .footer_wrapper {
        flex-wrap: wrap;
    }
    .footer_item1 {
        width: 100% !important;
        margin-bottom: 30px;
    }
    .footer_item2 {
        width: 100% !important;
    }
    .footer_img {
        height: unset!important;
    }
}

@media (max-width: 430px) {
    .footer_news_form_input_button_wrapper {
        flex-wrap: wrap;
        height: unset!important;
    }
    .footer_news_form_input_field2 {
        width: 100% !important;
    }
    .subscribe_to_news_btn {
        width: 100% !important;
    }
    .footer_navigation_link {
        font-size: 14px!important;
    }
}

.error_text {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    color: red;
    font-weight: 500;
    margin-top: 5px;
    margin-bottom: 10px;
}

.footer_img {
    width: 100%;
    /*max-width: 320px;*/
    height: 200px;
}
.footer_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
